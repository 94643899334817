import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { axisClasses } from '@mui/x-charts';

const LineChartt = ({ AllEvaluations }) => {
  const theme = useTheme();

  // Array of month names
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  const completedData = Array(12).fill(0);
  const failedData = Array(12).fill(0);

  // Process evaluations data
  AllEvaluations?.forEach((evaluation) => {
    const dateStr = evaluation.updatedAt;
    if (!dateStr) return;

    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return;

    const month = date.getMonth(); // get the month (0-11)
    if (evaluation.status === 'Completed') {
      completedData[month] += 1;
    } else if (evaluation.status === 'Failed') {
      failedData[month] += 1;
    }
  });

  // Create dataset with month (1-12) as 'x' value
  const dataset = monthNames.map((month, index) => ({
    x: index + 1, // x is 1-12 for the months
    Completed: completedData[index] || 0,
    Failed: failedData[index] || 0,
  }));

  return (
    <Stack sx={{ mr: 8 }}>
      <LineChart
        dataset={dataset}
        // xAxis={[
        //   {
        //     dataKey: 'x', // bind to 'x' key in dataset
        //     
        //     formatter: (value) => monthNames[value - 1], // Maps 1-12 to Jan-Dec
        //   },
        // ]}
        xAxis={[{ scaleType: 'point', data: monthNames , label: 'Month',}]}
        yAxis={[
          {
            label: 'Number of Evaluations',
          },
        ]}
        series={[
          { dataKey: 'Completed', label: 'Completed', color: theme.palette.primary.main },
          { dataKey: 'Failed', label: 'Failed', color: theme.palette.error.main },
        ]}
        height={400}
        width={800}
        margin={{ left: 70, right: 50, top: 30, bottom: 30 }}
        grid={{ vertical: true, horizontal: true }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
          },
        }}
      />
    </Stack>
  );
};

export default LineChartt;
