import React, { useMemo, useState } from "react";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Stack, Box, TextField, LinearProgress, Alert } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../../Hoc/withAuth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { format, parseISO, startOfDay } from "date-fns";

const QueueLevelReport = ({ user }) => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: AllEvaluations, isLoading } = useQuery(
    ["getAllEvaluations", startDate, endDate],
    async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/AllEvaluations`
      );

      if (startDate && endDate) {
        url.searchParams.append("startDate", format(startDate, "yyyy-MM-dd"));
        url.searchParams.append("endDate", format(endDate, "yyyy-MM-dd"));
      }

      const response = await axios.get(url.toString(), config);
      return response?.data?.data || [];
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const processedData = useMemo(() => {
    if (!AllEvaluations) return [];

    const campaignMap = new Map();

    AllEvaluations.forEach((evals) => {
      const campaignId = evals.campaign_id;
      const updatedAt = parseISO(evals.updatedAt);

      if (
        startDate &&
        endDate &&
        (updatedAt < startDate || updatedAt > endDate)
      ) {
        return;
      }

      if (!campaignMap.has(campaignId)) {
        campaignMap.set(campaignId, {
          id: campaignId,
          campaign_name: evals?.campaign?.name || "Unknown Campaign",
          createdAt: evals.createdAt,
          updatedAt: evals.updatedAt,
          evaluations: 0,
          new: 0,
          busy: 0,
          completed: 0,
          failed: 0,
          reEvaluations: 0,
          passrate: 0,
          totalScore: 0,
          evaluationsWithScore: 0,
          failrate: 0,
        });
      }

      const campaignStats = campaignMap.get(campaignId);

      campaignStats.evaluations++;

      if (evals.status === "New") campaignStats.new++;
      else if (evals.status === "Busy") campaignStats.busy++;
      else if (evals.status === "Completed") campaignStats.completed++;
      else if (evals.status === "Done" || evals.status === "Failed")
        campaignStats.failed++;
      else if (
        evals.status === "Re-Evaluation Requested" ||
        evals.status === "Dispute Requested"
      )
        campaignStats.reEvaluations++;

      campaignStats.passrate =
        (campaignStats.completed / campaignStats.evaluations) * 100;
      campaignStats.failrate =
        (campaignStats.failed / campaignStats.evaluations) * 100;

      if (evals.total_score) {
        campaignStats.totalScore += evals.total_score;
        campaignStats.evaluationsWithScore++;
        campaignStats.averageScore =
          campaignStats.totalScore / campaignStats.evaluationsWithScore;
      }
    });

    return Array.from(campaignMap.values());
  }, [AllEvaluations, startDate, endDate]);

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "From",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "updatedAt",
      headerName: "To",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "evaluations",
      headerName: "Total Evaluations",
      width: 150,
    },
    {
      field: "new",
      headerName: "New",
      width: 100,
    },
    {
      field: "busy",
      headerName: "Busy",
      width: 100,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 100,
    },
    {
      field: "failed",
      headerName: "Failed",
      width: 100,
    },
    {
      field: "reEvaluations",
      headerName: "Re-evaluations/Disputes",
      width: 200,
    },
    {
      field: "passrate",
      headerName: "Pass Rate",
      width: 150,
      valueFormatter: (params) => params.value.toFixed(2) + "%",
    },
    {
      field: "failrate",
      headerName: "Failure Rate",
      width: 150,
      valueFormatter: (params) => params.value.toFixed(2) + "%",
    },
  ];

  return (
    <Stack spacing={2} mt={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
          <Box mx={2}>to</Box>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <LinearProgress />
      ) : processedData.length > 0 ? (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={processedData}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            disableSelectionOnClick
            density="compact"
          />
        </div>
      ) : (
        <Alert severity="info">No Data Found</Alert>
      )}
    </Stack>
  );
};

export default withAuth(QueueLevelReport);
