import React, { useState, useMemo } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  TextField,
} from "@mui/material";
import withAuth from "../../../../../../../../Hoc/withAuth";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, startOfDay } from "date-fns";
import LineChartt from "./LineChart";

const Cards = ({ user, validRows }) => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  // Fetching evaluations within the selected date range
  const { data: AllEvaluations = [] } = useQuery(
    ["getAllEvaluations", startDate, endDate],
    async () => {
      const url = new URL(`${process.env.REACT_APP_API_URL}/QAInbound/user/`);
      url.searchParams.append("startDate", format(startDate, "yyyy-MM-dd"));
      url.searchParams.append("endDate", format(endDate, "yyyy-MM-dd"));

      const response = await axios.get(url.toString(), config);
      return response?.data?.data || [];
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  // Memoizing AllEvaluations to prevent unnecessary re-renders
  const memoizedEvaluations = useMemo(() => AllEvaluations, [AllEvaluations]);

  // Filter evaluations within the selected date range
  const filteredRows = memoizedEvaluations.filter((row) => {
    const rowDate = new Date(row.updatedAt);
    return rowDate >= startDate && rowDate <= endDate;
  });

  // Calculate percentages and counts
  const totalEvaluations = filteredRows.length;
  const failedCount = filteredRows.filter(
    (row) => row.status === "Failed"
  ).length;
  const failedPercentage =
    totalEvaluations > 0 ? (failedCount / totalEvaluations) * 100 : 0;

  const passedCount = filteredRows.filter(
    (row) => row.status === "Completed"
  ).length;
  const passedPercentage =
    totalEvaluations > 0 ? (passedCount / totalEvaluations) * 100 : 0;

  const rejectedCount = filteredRows.filter(
    (row) =>
      row.status === "Re-Evaluation Requested" ||
      row.status === "Dispute Requested"
  ).length;
  const rejectedPercentage =
    totalEvaluations > 0 ? (rejectedCount / totalEvaluations) * 100 : 0;

  return (
    <div>
      {/* Date Range Pickers */}
      <Stack direction="row" justifyContent="space-between">
      <Stack >
        <Box display="flex" alignItems="center" sx={{ mt: 2, ml: 2, mr: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(startOfDay(newValue))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "200px", margin: "normal" }}
                />
              )}
            />
            <Box mx={2}> - </Box>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(startOfDay(newValue))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "200px", margin: "normal" }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        {/* Cards */}
        <Box>
        <Stack direction="column">
          <Stack direction="row" spacing={4} alignItems="center" sx={{ p: 2 }}>
            {/* Pass Rate Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Pass Rate (%)
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {passedPercentage.toFixed(2)}%
                </Typography>
              </CardContent>
            </Card>

            {/* Failed Rate Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Failed Rate (%)
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {failedPercentage.toFixed(2)}%
                </Typography>
              </CardContent>
            </Card>
          </Stack>

          <Stack direction="row" spacing={4} alignItems="center" sx={{ p: 2 }}>
            {/* Rejected Rate Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Rejected Rate (%)
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {rejectedPercentage.toFixed(2)}%
                </Typography>
              </CardContent>
            </Card>

            {/* Total Evaluations Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Total Evaluations
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {totalEvaluations}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
          
        </Stack>
        </Box>
        </Stack>
        <LineChartt AllEvaluations={validRows} />
      </Stack>
    </div>
  );
};

export default withAuth(Cards);
