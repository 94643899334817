import axios from "axios";

const initialState = {
  qaQueuesList: [],
  qaQueuesListLoading: false,
  qaQueuesListError: null,
  qaQueuesListSuccess: false,
  qaQueue: {},
  qaQueueTeams: [],
  queueEvaluations: [],
  queueEvaluation: {},
  CreateQEvalSuccess: false,
  linkedCampaigns: [],
  evaluationRecordings: [],
  sale: {},
};

const GET_QA_QUEUES_LIST_REQUEST = "GET_QA_QUEUES_LIST_REQUEST";
const GET_QA_QUEUES_LIST_SUCCESS = "GET_QA_QUEUES_LIST_SUCCESS";
const GET_QA_QUEUES_LIST_FAILURE = "GET_QA_QUEUES_LIST_FAILURE";

const GET_QA_QUEUE_CAMPAIGNS_REQUEST = "GET_QA_QUEUE_CAMPAIGNS_REQUEST";
const GET_QA_QUEUE_CAMPAIGNS_SUCCESS = "GET_QA_QUEUE_CAMPAIGNS_SUCCESS";
const GET_QA_QUEUE_CAMPAIGNS_FAILURE = "GET_QA_QUEUE_CAMPAIGNS_FAILURE";

const GET_QA_QUEUE_BY_ID = "GET_QA_QUEUE_BY_ID";
const GET_QA_QUEUE_BY_ID_SUCCESS = "GET_QA_QUEUE_BY_ID_SUCCESS";
const GET_QA_QUEUE_BY_ID_FAILURE = "GET_QA_QUEUE_BY_ID_FAILURE";

const CREATE_QA_QUEUE_REQUEST = "CREATE_QA_QUEUE_REQUEST";
const CREATE_QA_QUEUE_SUCCESS = "CREATE_QA_QUEUE_SUCCESS";
const CREATE_QA_QUEUE_FAILURE = "CREATE_QA_QUEUE_FAILURE";

const UPDATE_QA_QUEUE_REQUEST = "UPDATE_QA_QUEUE_REQUEST";
const UPDATE_QA_QUEUE_SUCCESS = "UPDATE_QA_QUEUE_SUCCESS";
const UPDATE_QA_QUEUE_FAILURE = "UPDATE_QA_QUEUE_FAILURE";

const ADD_TEAM_TO_QUEUE_REQUEST = "ADD_TEAM_TO_QUEUE_REQUEST";
const ADD_TEAM_TO_QUEUE_SUCCESS = "ADD_TEAM_TO_QUEUE_SUCCESS";
const ADD_TEAM_TO_QUEUE_FAILURE = "ADD_TEAM_TO_QUEUE_FAILURE";

const GET_QA_QUEUE_TEAMS_REQUEST = "GET_QA_QUEUE_TEAMS_REQUEST";
const GET_QA_QUEUE_TEAMS_SUCCESS = "GET_QA_QUEUE_TEAMS_SUCCESS";
const GET_QA_QUEUE_TEAMS_FAILURE = "GET_QA_QUEUE_TEAMS_FAILURE";

const UPDATE_QA_QUEUE_TEAMS_REQUEST = "UPDATE_QA_QUEUE_TEAMS_REQUEST";
const UPDATE_QA_QUEUE_TEAMS_SUCCESS = "UPDATE_QA_QUEUE_TEAMS_SUCCESS";
const UPDATE_QA_QUEUE_TEAMS_FAILURE = "UPDATE_QA_QUEUE_TEAMS_FAILURE";

const CREATE_QUEUE_EVALUATION_REQUEST = "CREATE_QUEUE_EVALUATION_REQUEST";
const CREATE_QUEUE_EVALUATION_SUCCESS = "CREATE_QUEUE_EVALUATION_SUCCESS";
const CREATE_QUEUE_EVALUATION_FAILURE = "CREATE_QUEUE_EVALUATION_FAILURE";

const GET_ALL_QUEUE_EVALUATIONS_REQUEST = "GET_ALL_QUEUE_EVALUATIONS_REQUEST";
const GET_ALL_QUEUE_EVALUATIONS_SUCCESS = "GET_ALL_QUEUE_EVALUATIONS_SUCCESS";
const GET_ALL_QUEUE_EVALUATIONS_FAILURE = "GET_ALL_QUEUE_EVALUATIONS_FAILURE";

const GET_QUEUE_EVAL_BY_ID_REQUEST = "GET_QUEUE_EVAL_BY_ID_REQUEST";
const GET_QUEUE_EVAL_BY_ID_SUCCESS = "GET_QUEUE_EVAL_BY_ID_SUCCESS";
const GET_QUEUE_EVAL_BY_ID_FAILURE = "GET_QUEUE_EVAL_BY_ID_FAILURE";

const GET_EVALUATION_RECORDINGS_REQUEST = "GET_EVALUATION_RECORDINGS_REQUEST";
const GET_EVALUATION_RECORDINGS_SUCCESS = "GET_EVALUATION_RECORDINGS_SUCCESS";
const GET_EVALUATION_RECORDINGS_FAILURE = "GET_EVALUATION_RECORDINGS_FAILURE";

const GET_SALE_BY_REF_REQUEST = "GET_SALE_BY_REF_REQUEST";
const GET_SALE_BY_REF_SUCCESS = "GET_SALE_BY_REF_SUCCESS";
const GET_SALE_BY_REF_FAILURE = "GET_SALE_BY_REF_FAILURE";

const CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST =
  "CREATE_QUESTIONS_ANSWERS_REQUEST";
const CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS =
  "CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS";
const CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE =
  "CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE";

const GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST =
  "GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST";
const GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS =
  "GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS";
const GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE =
  "GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE";

const ADD_RECORDING_TO_EVALUATION_REQUEST =
  "ADD_RECORDING_TO_EVALUATION_REQUEST";
const ADD_RECORDING_TO_EVALUATION_SUCCESS =
  "ADD_RECORDING_TO_EVALUATION_SUCCESS";
const ADD_RECORDING_TO_EVALUATION_FAILURE =
  "ADD_RECORDING_TO_EVALUATION_FAILURE";

export const RESET_QA_QUEUES = "RESET_QA_QUEUES";

const QaDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALE_BY_REF_REQUEST:
      return {
        ...state,
        saleByRefLoading: true,
        saleByRefError: null,
        saleByRefSuccess: false,
      };
    case GET_SALE_BY_REF_SUCCESS:
      return {
        ...state,
        saleByRefLoading: false,
        saleByRefError: null,
        saleByRefSuccess: true,
        sale: action.payload,
      };
    case GET_SALE_BY_REF_FAILURE:
      return {
        ...state,
        saleByRefLoading: false,
        saleByRefError: action.payload,
        saleByRefSuccess: false,
      };

    case GET_QA_QUEUES_LIST_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_QA_QUEUES_LIST_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesList: action.payload,
        queueQuestionsAndAnswers: [],
      };
    case GET_QA_QUEUES_LIST_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case GET_QA_QUEUE_CAMPAIGNS_REQUEST:
      return {
        ...state,
        qaQueueCampaignsLoading: true,
        qaQueueCampaignsError: null,
        qaQueueCampaignsSuccess: false,
      };
    case GET_QA_QUEUE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        qaQueueCampaignsLoading: false,
        qaQueueCampaignsError: null,
        linkedCampaigns: action.payload,
      };
    case GET_QA_QUEUE_CAMPAIGNS_FAILURE:
      return {
        ...state,
        qaQueueCampaignsLoading: false,
        qaQueueCampaignsError: action.payload,
        qaQueueCampaignsSuccess: false,
      };

    case CREATE_QA_QUEUE_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case CREATE_QA_QUEUE_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        qaQueuesList: [...state.qaQueuesList, action.payload],
      };
    case CREATE_QA_QUEUE_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };
    case UPDATE_QA_QUEUE_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case UPDATE_QA_QUEUE_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        qaQueue: action.payload,

        // qaQueuesList: [
        //   ...state.qaQueuesList?.map((qaQueue) =>
        //     qaQueue.id === action.payload.id ? action.payload : qaQueue
        //   ),
        // ],
      };
    case UPDATE_QA_QUEUE_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case GET_QA_QUEUE_BY_ID:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_QA_QUEUE_BY_ID_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,

        qaQueue: action.payload,
      };
    case GET_QA_QUEUE_BY_ID_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case ADD_TEAM_TO_QUEUE_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };

    case ADD_TEAM_TO_QUEUE_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        addedTeamToQaQueueSuc: true,
        qaQueueTeams: action.payload,
      };
    case ADD_TEAM_TO_QUEUE_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case GET_QA_QUEUE_TEAMS_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_QA_QUEUE_TEAMS_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        qaQueueTeams: action.payload,
      };
    case GET_QA_QUEUE_TEAMS_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case UPDATE_QA_QUEUE_TEAMS_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case UPDATE_QA_QUEUE_TEAMS_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        qaQueueTeams: state.qaQueueTeams?.map((qaQueueTeam) =>
          qaQueueTeam.id === action.payload.id
            ? {
                ...qaQueueTeam,
                ...action.payload,
              }
            : qaQueueTeam
        ),
      };
    case UPDATE_QA_QUEUE_TEAMS_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case CREATE_QUEUE_EVALUATION_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        CreateQEvalSuccess: false,
        qaQueuesListSuccess: false,
      };

    case CREATE_QUEUE_EVALUATION_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        CreateQEvalSuccess: true,
        queueEvaluations: [...state.queueEvaluations, action.payload],
      };
    case CREATE_QUEUE_EVALUATION_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case GET_ALL_QUEUE_EVALUATIONS_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_ALL_QUEUE_EVALUATIONS_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        queueEvaluations: action.payload,
      };
    case GET_ALL_QUEUE_EVALUATIONS_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case GET_QUEUE_EVAL_BY_ID_REQUEST:
      return {
        ...state,
        CreateQEvalSuccess: false,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_QUEUE_EVAL_BY_ID_SUCCESS:
      return {
        ...state,

        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        queueEvaluation: action.payload,
      };
    case GET_QUEUE_EVAL_BY_ID_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
        addedQuestionsAndAnswers: false,
      };

    case CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        addedQuestionsAndAnswers: true,
        queueQuestionsAndAnswers: [
          ...action.payload.map((question) => {
            delete question.id;
            return {
              ...question,
              qa_answers: question.qa_queue_question_answers,
            };
          }),
          ...action.payload,
        ],
      };
    case CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        addedQuestionsAndAnswers: false,
        qaQueuesListSuccess: false,
      };

    case GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        queueQuestionsAndAnswers: action.payload,
      };
    case GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case GET_EVALUATION_RECORDINGS_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case GET_EVALUATION_RECORDINGS_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        evaluationRecordings: action.payload,
      };
    case GET_EVALUATION_RECORDINGS_FAILURE:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case ADD_RECORDING_TO_EVALUATION_REQUEST:
      return {
        ...state,
        qaQueuesListLoading: true,
        recordingAdded: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
      };
    case ADD_RECORDING_TO_EVALUATION_SUCCESS:
      return {
        ...state,
        qaQueuesListLoading: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: true,
        recordingAdded: true,
        evaluationRecordings: [...state.evaluationRecordings, action.payload],
      };
    case ADD_RECORDING_TO_EVALUATION_FAILURE:
      return {
        ...state,
        recordingAdded: false,
        qaQueuesListLoading: false,
        qaQueuesListError: action.payload,
        qaQueuesListSuccess: false,
      };

    case RESET_QA_QUEUES:
      return {
        ...state,
        qaQueuesListLoading: false,
        recordingAdded: false,
        qaQueuesListError: null,
        qaQueuesListSuccess: false,
        addedTeamToQaQueueSuc: false,
        addedQuestionsAndAnswers: false,
        CreateQEvalSuccess: false,
      };

    default:
      return state;
  }
};

export default QaDuck;

export const getSaleByRef = (uni_id) => async (dispatch, getState) => {
  dispatch({
    type: GET_SALE_BY_REF_REQUEST,
  });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/salesman/sales/ref/${uni_id}`,
      config
    );

    dispatch({
      type: GET_SALE_BY_REF_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SALE_BY_REF_FAILURE,
      payload: err.response,
    });
  }
};

export const getQaQueuesList = () => async (dispatch, getState) => {
  dispatch({
    type: GET_QA_QUEUES_LIST_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/queues`,
      config
    );
    dispatch({
      type: GET_QA_QUEUES_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_QUEUES_LIST_FAILURE,
      payload: err.response,
    });
  }
};

export const createQaQueue = (formData) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_QA_QUEUE_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/qa/queues`,
      formData,
      config
    );
    dispatch({
      type: CREATE_QA_QUEUE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_QA_QUEUE_FAILURE,
      payload: err.response,
    });
  }
};

export const updateQaQueue = (formData) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_QA_QUEUE_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/qa/queues/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_QA_QUEUE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_QA_QUEUE_FAILURE,
      payload: err.response,
    });
  }
};

export const getQaQueueById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_QA_QUEUE_BY_ID,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/queues/${id}`,
      config
    );
    dispatch({
      type: GET_QA_QUEUE_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_QUEUE_BY_ID_FAILURE,
      payload: err.response,
    });
  }
};

export const addTeamToQueue = (id, formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TEAM_TO_QUEUE_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/qa/queues/${id}/teams`,
      formData,
      config
    );
    dispatch({
      type: ADD_TEAM_TO_QUEUE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_TEAM_TO_QUEUE_FAILURE,
      payload: err.response,
    });
  }
};

export const getQaQueueTeams = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_QA_QUEUE_TEAMS_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/queues/${id}/teams`,
      config
    );
    dispatch({
      type: GET_QA_QUEUE_TEAMS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_QUEUE_TEAMS_FAILURE,
      payload: err.response,
    });
  }
};

export const updateQaQueueTeams =
  (id, formData) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_QA_QUEUE_TEAMS_REQUEST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/teams/${formData.team_id}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_QA_QUEUE_TEAMS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_QA_QUEUE_TEAMS_FAILURE,
        payload: err.response,
      });
    }
  };

export const CreateQueueEvaluation =
  (queue_id, values) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_QUEUE_EVALUATION_REQUEST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue_id}/evaluations`,
        values,
        config
      );
      dispatch({
        type: CREATE_QUEUE_EVALUATION_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_QUEUE_EVALUATION_FAILURE,
        payload: err.response,
      });
    }
  };

export const GetAllQueueEvaluations =
  (queue_id) => async (dispatch, getState) => {
    dispatch({
      type: GET_ALL_QUEUE_EVALUATIONS_REQUEST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue_id}/evaluations`,
        config
      );
      dispatch({
        type: GET_ALL_QUEUE_EVALUATIONS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_QUEUE_EVALUATIONS_FAILURE,
        payload: err.response,
      });
    }
  };

export const getQaEvaluationById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_QUEUE_EVAL_BY_ID_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/${id}`,
      config
    );
    dispatch({
      type: GET_QUEUE_EVAL_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QUEUE_EVAL_BY_ID_FAILURE,
      payload: err.response,
    });
  }
};

export const CreateQuestionsAndAnswersForQueue =
  (queue, questionList) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue}/questions`,
        questionList,
        config
      );
      dispatch({
        type: CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE,
        payload: err.response,
      });
    }
  };

export const GetAllQuestionsAndAnswersForQueue =
  (queue) => async (dispatch, getState) => {
    dispatch({
      type: GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_REQUEST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue}/questions`,
        config
      );
      dispatch({
        type: GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_QUESTIONS_AND_ANSWERS_FOR_QUEUE_FAILURE,
        payload: err.response,
      });
    }
  };

export const getQaQueueCampaigns = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_QA_QUEUE_CAMPAIGNS_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/queues/${id}/campaigns`,
      config
    );
    dispatch({
      type: GET_QA_QUEUE_CAMPAIGNS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_QUEUE_CAMPAIGNS_FAILURE,
      payload: err.response,
    });
  }
};

export const getEvaluationRecordings = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_EVALUATION_RECORDINGS_REQUEST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/${id}/recording`,
      config
    );
    dispatch({
      type: GET_EVALUATION_RECORDINGS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_EVALUATION_RECORDINGS_FAILURE,
      payload: err.response,
    });
  }
};

export const addRecordingToEvaluation =
  (evaluation_id, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_RECORDING_TO_EVALUATION_REQUEST,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/${evaluation_id}/recording`,
        formData,
        config
      );
      dispatch({
        type: ADD_RECORDING_TO_EVALUATION_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADD_RECORDING_TO_EVALUATION_FAILURE,
        payload: err.response,
      });
    }
  };

export const UpdateEvaluation =
  ({ id, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_QUEUE_EVAL_BY_ID_REQUEST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/evaluationUpdate/${id}`,
        { ...values },
        config
      );

      dispatch({
        type: GET_QUEUE_EVAL_BY_ID_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ADD_RECORDING_TO_EVALUATION_FAILURE,
        payload: err.response,
      });
    }
  };
