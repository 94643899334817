import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  Stack,
  TextField,
  CardHeader,
  FormControl,
  FormLabel,
  FormControlLabel,
  Tooltip,
  Radio,
  RadioGroup,
  Button,
  LinearProgress,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Info from "@mui/icons-material/Info";
import withAuth from "../../../../../../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";

const Reinitialize = ({ callListeningTime, user, setStart, endEvaluation }) => {
  const { id, evalID } = useParams();
  const UserEmail = user?.email;
  const [allQuestions, setAllQuestions] = useState([]);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  console.log (complete)


  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID", evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/Evaluations/${evalID}`,
        config
      ),
  });

  const  oldtime = getEvaluationByID?.data?.data?.data?.call_listening_time || 0;

  const SendEmail = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/QaInbound/admin/evaluations/${id}/${evalID}/sendemail`,{}, config
      );
      return console.log("Email Sent successfully", response);
    },
    onSuccess: () => {
      console.log("Email Sent successfully");
    },
    onError: (error) => {
      console.error("Failed to send email:", error);
    },
  });

  // Get Question Groups
  const getActiveQuestionGroups = useQuery({
    queryKey: ["getActiveQuestionGroups", id],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/?active=true&campaignId=${id}`,
        config
      ),
    onSuccess: (data) => {
      let questionGroups = data?.data?.data?.map((group) => {
        let groups = group.question?.map((question) => {
          return {
            ...question,
            answer: {
              comments: question.comments,
              answer_value: question.answer_value,
              text_field_input: question.text_field_input,
              yes_no_value: question.yes_no_value,
              answered: false,
            },
          };
        });
        return {
          ...group,
          question: groups,
        };
      });

      setAllQuestions(questionGroups.sort((a, b) => a.order - b.order));
    },
  });

  const evaluationResults = useQuery({
    queryKey: ["getActiveQuestionGroups", evalID],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/${evalID}/results`,
        config
      );
      return response?.data;
    },
    onSuccess: (data) => {
      let results = data?.data;

      setAllQuestions((prevQuestions) => {
        return prevQuestions.map((group) => ({
          ...group,
          question: group.question.map((question) => {
            let result = results.find((r) => r.question_id === question.id);
            if (result) {
              return {
                ...question,
                answer: {
                  ...question.answer,
                  comments: result.comments,
                  answer_value: result.answer_value,
                  text_field_input: result.text_field_input,
                  yes_no_value:
                    result.yes_no_value !== null ? result.yes_no_value : null,
                  answered:
                    result.answer_value ||
                    result.text_field_input ||
                    result.yes_no_value,
                },
              };
            }
            return question;
          }),
        }));
      });
    },
  });

  useEffect(() => {
    let isComplete = allQuestions?.every((group) => {
      return group.question.every((question) => {
        return question.answer.answered;
      });
    });

    setComplete(isComplete);
  }, [allQuestions]);

  const handleCompleteEvaluation = () => {
    let data = {
      evaluation_id: evalID,
      email: UserEmail,
      call_listening_time: callListeningTime+oldtime,
      results: allQuestions,
    };

    endEvaluation.mutate(data, {
      onSuccess: () => {
        setStart(false);
        sendEmail(evalID, id);
      },
      onError: (error) => {
        console.error("Failed to complete evaluation:", error);
      },
    });
  };

  const sendEmail = (evalID, id) => {  
    SendEmail.mutate({ evalID, id });
  };

  const handleSaveProgress = () => {
    
    const data = {
      evaluation_id: evalID,
      results: allQuestions?.map((group) => ({
        ...group,
        question: group.question.map((question) => {
          return {
            ...question,
            answer: {
              ...question.answer,
              answer_value:
                question.answer.answer_value !== null
                  ? question.answer.answer_value
                  : null,
              yes_no_value:
                question.answer.yes_no_value !== null
                  ? question.answer.yes_no_value
                  : null,
              text_field_input:
                question.answer.text_field_input !== null
                  ? question.answer.text_field_input
                  : null,
            },
          };
        }),
      })),
      email: UserEmail,
      call_listening_time: callListeningTime+oldtime,
    };

    endEvaluation.mutate(data, {
      onSuccess: () => {
   
        setShowAlert(true);
       
        setTimeout(() => {
          setShowAlert(false);
          navigate(`/CDA/inbound/user/`);
          setStart(false);
        }, 1000); 
      },
      onError: () => {
        setShowAlert(true); 
      }
    });
  };

  if (getActiveQuestionGroups.isLoading || evaluationResults.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      {allQuestions?.map((group) => (
        <Stack key={group.id} sx={{ mt: 2 }}>
          <CardHeader title={group.name} subheader={group.group_type} />
          <CardContent>
            {group.question.map((question) => {
              if (question.question_type === "Yes/No") {
                return (
                  <YesNoQuestion
                    key={question.id}
                    question={question}
                    handleSetValues={setAllQuestions}
                  />
                );
              } else if (question.question_type === "Text Input") {
                return (
                  <TextQuestion
                    key={question.id}
                    question={question}
                    handleSetValues={setAllQuestions}
                  />
                );
              }
              return (
                <ScoreQuestion
                  key={question.id}
                  question={question}
                  handleSetValues={setAllQuestions}
                />
              );
            })}
          </CardContent>
        </Stack>
      ))}

      {complete ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCompleteEvaluation}
          sx={{ mt: 2, width: "100%" }}
        >
          Submit
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveProgress}
          sx={{ mt: 2, width: "100%" }}
        >
          Save Progress
        </Button>
      )}
            <AlertPopup 
        open={showAlert}
        message="Progress saved successfully"
        severity="success"
        onClose={() => setShowAlert(false)}
      />
    </div>
  );
};

export default withAuth(Reinitialize);

const YesNoQuestion = ({ question, handleSetValues }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;

    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  yes_no_value:
                    inputValue === "Yes"
                      ? true
                      : inputValue === "No"
                      ? false
                      : null,
                  answer_value: inputValue,
                  answered: true,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  const handleCommentChange = (e) => {
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  comments: e.target.value,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  return (
    <Card sx={{mb:2}}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{ fontSize: "15px", color: "dark" }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>

            <RadioGroup
              value={question.answer.answer_value || null}
              onChange={handleChange}
              aria-labelledby={`demo-${question.id}`}
              name="radio-buttons-group"
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Stack>
        <TextField
          value={question.answer.comments || ""}
          onChange={handleCommentChange}
          variant="outlined"
          placeholder="Enter your comments"
          fullWidth
          multiline
          rows={3}
          sx={{ mt: 1, width: "100%" }}
        />
      </CardContent>
    </Card>
  );
};

const TextQuestion = ({ question, handleSetValues }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value; // Trim whitespace

    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  text_field_input: inputValue,
                  answer_value: inputValue,
                  // Only mark as answered if there's actual content
                  answered: inputValue !== "",
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };
  return (
    <Card sx={{mb:2}}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{
                        fontSize: "15px",
                        color: "dark",
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>
          </Stack>
          <TextField
            value={question.answer.text_field_input || ""}
            onChange={handleChange}
            variant="outlined"
            placeholder="Enter your response"
            fullWidth
            multiline
            rows={3}
            sx={{ mt: 1, width: "100%" }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

const ScoreQuestion = ({ question, handleSetValues }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  answer_value: inputValue,
                  answered: true,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  const handleCommentChange = (e) => {
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  comments: e.target.value,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  return (
    <Card sx={{mb:2}}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{
                        fontSize: "15px",
                        color: "dark",
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>

            <RadioGroup
              onChange={handleChange}
              aria-labelledby={`demo-${question.id}`}
              value={question.answer.answer_value || null}
              row
              name="radio-buttons-group"
            >
              <FormControlLabel value={1} control={<Radio />} label="1=Poor" />
              <FormControlLabel value={2} control={<Radio />} label="2=Fair" />
              <FormControlLabel value={3} control={<Radio />} label="3=Good" />
              <FormControlLabel value={4} control={<Radio />} label="4=Very Good" />
              <FormControlLabel value={5} control={<Radio />} label="5=Excellent" />
            </RadioGroup>
          </FormControl>
        </Stack>
        <TextField
          value={question.answer.comments || ""}
          onChange={handleCommentChange}
          variant="outlined"
          placeholder="Enter your comments"
          fullWidth
          multiline
          rows={3}
          sx={{ mt: 1, width: "100%" }}
        />
      </CardContent>
    </Card>
  );
};
