import React, { useEffect } from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { Typography, Button, Stack } from "@mui/material";
import withAuth from "../../../../../Hoc/withAuth";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { QueryStats } from "@mui/icons-material";

const InboundManagerHome = ({ user }) => {
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: CampaignData = [] } = useQuery(
    ["getAllEvaluations"],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/manager`,
        config
      );
      return response?.data?.data || [];
    },
    {
      onError: (error) => {
        console.error("Error fetching evaluations:", error);
      },
    }
  );

  const rows = Array.isArray(CampaignData)
    ? CampaignData?.map((evaluation) => ({
        id: evaluation.id,
        name: evaluation.name,
        description: evaluation.description,
        type: evaluation.type,
        callbi_scorecard: evaluation.callbi_scorecard,
      }))
    : [];

  useEffect(() => {}, [CampaignData]);

  const columns = [
    {
      field: "View",
      headerName: "View",
      renderCell: (params) => (
        <Button
          onClick={() => {
            navigate(`/CDA/inbound/manager/${params.id}/evaluations`);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "type",
      headerName: "Campaign Type",
      width: 200,
    },
    {
      field: "callbi_scorecard",
      headerName: "Callbi Scorecard",
      width: 200,
    },
  ];

  return (
    <div>
      <PageHeader
        title="QA Inbound User"
        subheader="Manage your QA Inbound evaluations"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound Manager", href: "/CDA/inbound/manager" },
        ]}
      />
      <Stack container justifyContent="flex-start" sx={{ mt: 3, mb: 2 }}>
        <Typography variant="h6">Manager/Team Leader Campaigns</Typography>
      </Stack>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          startIcon={<QueryStats />}
          onClick={() => {
            navigate(`/CDA/inbound/manager/evaluations/EvaluationResults`);
          }}
        >
          View Stats
        </Button>
      </div>

      <Stack sx={{ height: "60vh" }}>
        <DataGridPremium
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
        />
      </Stack>
    </div>
  );
};

export default withAuth(InboundManagerHome);
