import { Button, Card, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { getQaQueueById, updateQaQueue } from "./State/QaDuck";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import ScoreCardSelector from "../../../../Admin/components/CallbiSelector";

const QaQueueEdit = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getQaQueueById(id));
  }, [dispatch, id]);
  

  const Qa = useSelector((state) => state.Qa);
  const { qaQueue, qaQueuesListSuccess } = Qa;


  return (
    <div>
      <AlertPopup
        open={qaQueuesListSuccess}
        message="Queue Updated Successfully"
        severity={"success"}
      />
      <Card sx={{ mb: 4 }}>
        <Stack
          sx={{ border: 1, p: 2 }}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <Typography gutterBottom variant="h6">
            Edit Queue
          </Typography>
        </Stack>
      </Card>
      <Formik
        initialValues={{
          id: qaQueue.id || "",
          queue_name: qaQueue.queue_name || "",
          queue_type: qaQueue.queue_type || "",
          score_card: qaQueue.score_card || "",
          required: qaQueue.required || "",
          updated_reason: "Updates",
          minEvaluation: qaQueue.minEvaluation || "",
          maxEvaluation: qaQueue.maxEvaluation || "",
          active: true,
        }}
        validationSchema={Yup.object({
          queue_name: Yup.string().required("Name is required"),
          queue_type: Yup.string().required("Queue Type is required"),
          score_card: Yup.string().required("Score Card is required"),
          required: Yup.number()
            .max(100)
            .required("Verification % is required"),
          active: Yup.boolean().required("Active is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          dispatch(updateQaQueue(values));
        }}
      >
        {(touched) => {
          return (
            <Form>
              <Stack spacing={2}>
                <TextfieldWrapper label="Queue Name" name="queue_name" />
                <SelectWrapper
                  label="Queue Type"
                  name="queue_type"
                  options={[
                    { value: "sale", label: "Sale" },
                    { value: "call", label: "Call" },
                    { value: "client", label: "Client" },
                    { value: "services", label: "Services" },
                  ]}
                />
                <ScoreCardSelector name="score_card" label="Score Card" />

                <TextfieldWrapper
                  label="Verification %"
                  name="required"
                  type="number"
                />
                <TextfieldWrapper
                  label="minEvaluation"
                  name="minEvaluation"
                  type="number"
                />
                <TextfieldWrapper
                  label="maxEvaluation"
                  name="maxEvaluation"
                  type="number"
                />
              </Stack>

              {touched.dirty && (
                <Button
                  fullWidth
                  type="submit"
                  sx={{ mt: 2 }}
                  variant="contained"
                >
                  Save
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QaQueueEdit;
