import React, { useMemo, useState } from "react";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Alert, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../Hoc/withAuth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { format, parseISO, startOfDay } from "date-fns";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const formatTimeToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${hours}h ${minutes}m ${remainingSeconds}s`;
};

const EvaluationUserStats = ({ user }) => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: AllEvaluations, isLoading } = useQuery(
    ["getAllEvaluations", startDate, endDate],
    async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${id}/AgentEvals`
      );

      if (startDate && endDate) {
        url.searchParams.append("startDate", format(startDate, "yyyy-MM-dd"));
        url.searchParams.append("endDate", format(endDate, "yyyy-MM-dd"));
      }

      const response = await axios.get(url.toString(), config);
      return response?.data?.data || [];
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const processedData = useMemo(() => {
    if (!AllEvaluations) return [];

    const agentMap = new Map();

    AllEvaluations.forEach((evals) => {
      const teamMember = evals.team_member_id;
      const updatedAt = parseISO(evals.updatedAt);

      if (
        startDate &&
        endDate &&
        (updatedAt < startDate || updatedAt > endDate)
      ) {
        return;
      }

      if (!agentMap.has(teamMember)) {
        agentMap.set(teamMember, {
          id: teamMember,
          agent_name: `${evals?.evaluation_users?.name} ${evals?.evaluation_users?.surname}`,
          createdAt: evals.createdAt,
          updatedAt: evals.updatedAt,
          evaluations: 0,
          new: 0,
          busy: 0,
          completed: 0,
          failed: 0,
          reEvaluations: 0,
          evaluationHours: 0,
          listeningTime: 0,
          passrate: 0,
          failrate: 0,
        });
      }

      const agentStats = agentMap?.get(teamMember);

      agentStats.evaluations++;

      // Update status counts
      if (evals.status === "New") agentStats.new++;
      else if (evals.status === "Busy") agentStats.busy++;
      else if (evals.status === "Completed") agentStats.completed++;
      else if (evals.status === "Done" || evals.status === "Failed")
        agentStats.failed++;
      else if (
        evals.status === "Re-Evaluation Requested" ||
        evals.status === "Dispute Requested"
      )
        agentStats.reEvaluations++;

      agentStats.passrate =
        (agentStats.completed / agentStats.evaluations) * 100;
      agentStats.failrate = (agentStats.failed / agentStats.evaluations) * 100;

      if (evals.time_tracking?.time) {
        const timeStr = evals.time_tracking.time;
        const [hours, minutes, seconds] = timeStr
          .match(/(\d+)h (\d+)m (\d+)s/)
          .slice(1)
          .map(Number);
        agentStats.evaluationHours += hours + minutes / 60 + seconds / 3600;
      }

      // Add listening time if available
      if (evals.call_listening_time) {
        agentStats.listeningTime += evals.call_listening_time;
      }
    });

    return Array.from(agentMap.values());
  }, [AllEvaluations, startDate, endDate]);

  const columns = [
    {
      field: "agent_name",
      headerName: "Agent Name",
      width: 150,
    },

    {
      field: "createdAt",
      headerName: "From",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "updatedAt",
      headerName: "To",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "evaluations",
      headerName: "Total Evaluations",
      width: 100,
    },
    {
      field: "new",
      headerName: "New",
      width: 100,
    },
    {
      field: "busy",
      headerName: "Busy",
      width: 100,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 100,
    },
    {
      field: "failed",
      headerName: "Failed",
      width: 100,
    },
    {
      field: "reEvaluations",
      headerName: "Re-evaluations/Disputes",
      width: 120,
    },
    {
      field: "passrate",
      headerName: "Pass Rate(%)",
      width: 100,
      valueFormatter: (params) => params.value.toFixed(2) + "%",
    },
    {
      field: "failrate",
      headerName: "Fail Rate(%)",
      width: 100,
      valueFormatter: (params) => params.value.toFixed(2) + "%",
    },
    {
      field: "evaluationHours",
      headerName: "Evaluation Time",
      width: 150,

      valueFormatter: (params) => params.value.toFixed(2),
    },
    {
      field: "listeningTime",
      headerName: "Listening Time",
      width: 150,

      valueFormatter: (params) => formatTimeToHMS(params.value),
    },
  ];

  console.log("Processed Data::", processedData);

  return (
    <Stack spacing={2} mt={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
          <Box mx={2}>to</Box>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <LinearProgress />
      ) : processedData.length > 0 ? (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={processedData}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            disableSelectionOnClick
            density="compact"
          />
        </div>
      ) : (
        <Alert severity="info">No Data Found</Alert>
      )}
    </Stack>
  );
};

export default withAuth(EvaluationUserStats);
